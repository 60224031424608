// Copyright (C) 2022 by Posit Software, PBC.

// WARNING: generated file. Do not edit.
// run `make NATIVE=no generate-error-codes` in the dashboard to regenerate/keep in sync w/backend error codes.

// ApiErrors reflects error codes sent by the backend. See `ApiErrorCode`.
const ApiErrors = {
  // An internal failure occurred.
  InternalFailure: 1,
  // The requested method or endpoint is not supported.
  BadRequest: 2,
  // The requested object ID is invalid.
  InvalidId: 3,
  // The requested object does not exist.
  ObjectDoesNotExist: 4,
  // Application name must be between 3 and 64 alphanumeric characters, dashes, and underscores.
  InvalidApplicationName: 5,
  // The password is not strong enough. Please try again.
  InadequatePassword: 6,
  // The requested username is not permitted.
  InvalidUserName: 7,
  // The requested username is already in use. Usernames are case sensitive. Please ensure you are using the correct case.
  DuplicateUserName: 8,
  // The requested user could not be found.
  InvalidUser: 9,
  // The requested object doesn&#39;t belong to you.
  NotObjectOwner: 10,
  // The requested filter could not be applied.
  InvalidFilterField: 11,
  // A required parameter is missing.
  ParameterMissing: 12,
  // The requested range is invalid.
  InvalidRange: 13,
  // Group name must be between less than 4096 alphanumeric characters.
  InvalidGroupName: 14,
  // The requested group name is already in use.
  DuplicateGroupName: 15,
  // The user is already a member of the group.
  DuplicateGroupEntry: 16,
  // The requested item could not be removed because it does not exist.
  RemoveNothing: 17,
  // The requested item could not be changed because it does not exist.
  UpdateNothing: 18,
  // You don&#39;t have permission to access this item.
  NoViewPermission: 19,
  // You don&#39;t have permission to remove this item.
  NoRemovePermission: 20,
  // You don&#39;t have permission to change this item.
  NoUpdatePermission: 21,
  // You don&#39;t have permission to perform this operation.
  PermissionDenied: 22,
  // You don&#39;t have permission to give the user this role.
  InvalidRoleAssignment: 23,
  // The requested operation requires authentication.
  AuthRequired: 24,
  // The parameter is invalid.
  InvalidParameter: 25,
  // An object with that name already exists.
  DuplicateName: 26,
  // This version of R is in use and cannot be deleted.
  RVersionInUse: 27,
  // No application bundle to deploy.
  BundleMissing: 28,
  // The token is expired. Authentication tokens must be claimed within one hour.
  TokenExpired: 29,
  // We couldn&#39;t log you in with the provided credentials. Please ask your administrator for assistance.
  InvalidLogin: 30,
  // Password change prohibited.
  NoPasswordChange: 31,
  // The requested filter is not valid.
  InvalidFilter: 32,
  // This user cannot be added as a collaborator because they don&#39;t have permission to publish content.
  InvalidCollaborator: 33,
  // The application&#39;s owner cannot be added as a collaborator or viewer.
  InvalidCollaboratorOwner: 34,
  // Cannot delete object as it is being used elsewhere.
  CannotDelete: 35,
  // This user&#39;s username has already been set and cannot be changed.
  NoUsernameChange: 36,
  // Schedules must have a start time and it must be after 1/1/2010.
  InvalidStartTime: 37,
  // The application&#39;s manifest is invalid or missing.
  InvalidManifest: 38,
  // The application does not support this action.
  InvalidApplicationAction: 39,
  // The current user has not been confirmed.
  UserNotConfirmed: 40,
  // The initial user must specify a password; it cannot be generated.
  InitialUserGeneratePassword: 41,
  // The user has already been confirmed.
  UserAlreadyConfirmed: 42,
  // This system has not been configured to send email. Please contact your administrator.
  MissingServerSenderEmail: 43,
  // The current user does not have an email address.
  UserMissingEmail: 44,
  // Invalid MinProcesses setting. The MinProcesses setting may not exceed the Scheduler.MinProcessesLimit setting.
  MinProcessesOverLimit: 45,
  // Internal user fields cannot be changed via API.
  ImmutableUserField: 46,
  // You cannot log in because the configured policy requires that the Posit Connect administrator provision your account first. Please contact your administrator.
  UserMustBeProvisioned: 47,
  // You cannot change the type of application once deployed. Try deploying this as a new application instead of updating an existing one.
  AppModeChanged: 48,
  // You don&#39;t have permission to lock/unlock this user.
  NoLockPermission: 49,
  // This user is locked.
  UserLocked: 50,
  // Vanity path conflicts with one or more already in use.
  VanityPathInUse: 51,
  // Vanity path is not permitted.
  ProhibitedVanityPath: 52,
  // Immutable field cannot be changed.
  ImmutableFields: 53,
  // You cannot set this content to run as the current user
  RunAsCurrentProhibited: 54,
  // Custom RunAs settings are prohibited for static content.
  RunAsStaticProhibited: 55,
  // The RunAs setting references a prohibited Unix account.
  RunAsUserProhibited: 56,
  // The RunAs setting does not reference a valid Unix account.
  RunAsUserNotFound: 57,
  // The RunAs setting references a Unix account that does not have the correct group membership.
  RunAsGroupMembership: 58,
  // There is no rendering available.
  NoRendering: 59,
  // This email address is not allowed to login to this server.
  DisallowedEmail: 60,
  // You cannot change the role of the only remaining administrator.
  SoleAdminDemotion: 61,
  // An API key name cannot be blank or have more than 80 characters.
  InvalidKeyName: 62,
  // There was a problem communicating with the LDAP authentication server. Please contact your administrator.
  LDAPServerUnavailable: 63,
  // The number of users permitted by this license has been exceeded. Please contact your administrator.
  UserLicenseExceeded: 64,
  // API applications are not permitted by your license.
  APINotLicensed: 65,
  // You cannot assign ownership to another user.
  CrossOwnership: 66,
  // You must provide the source_key for an existing variant
  SourceKeyRequired: 67,
  // You cannot promote a variant without a valid rendering
  PromotionWithoutRendering: 68,
  // The bundle ID of the source and target variants must match
  BundleIdMismatch: 69,
  // Target rendering is more recent than source rendering
  SourceRenderingStale: 70,
  // Only parameterized documents support promoting output
  InvalidPromotionSourceType: 71,
  // Not allowed to create schedule with different application association than its variant
  InvalidAppForVariantSchedule: 72,
  // You may not schedule ad-hoc variants
  CannotScheduleAdhocVariant: 73,
  // The requested report name is not permitted
  InvalidVariantName: 74,
  // You may not delete the active bundle for an application
  DeleteActiveBundle: 75,
  // A user using the same Unique ID already exists
  DuplicateUserKey: 76,
  // A tag name cannot be more than 255 characters
  NoLongTagName: 77,
  // A tag must have a name
  NoEmptyTagName: 78,
  // Cannot assign a category to an app
  NoCategoryAppAssignment: 79,
  // The target object does not match the provided version.
  IncorrectObjectVersion: 80,
  // Duplicate names are not permitted; a sibling tag or category already has this name
  NoDuplicateTagNames: 81,
  // The bundle for deployment must belong to the target application.
  BundleIdAppIdMismatch: 82,
  // Too many search results. Be more specific.
  TooManyResults: 83,
  // The token has already been claimed. Tokens can only be claimed once.
  TokenClaimed: 84,
  // A token using the same token key already exists
  DupicateToken: 85,
  // A new token MUST contain a public_key and token in the json body.
  MissingTokenAttributes: 86,
  // The request body cannot be parsed
  CannotParseRequestBody: 87,
  // Cannot apply the requested change because it would make the target object an ancestor of itself.
  InvalidRelationship: 88,
  // Unable to send email. Please contact your administrator.
  EmailFailure: 89,
  // User self-registration is disabled.
  UserSelfRegistrationDisabled: 90,
  // The external authentication provider did not provide a valid username.
  InvalidExternalAuthUsername: 91,
  // XSRF token mismatch
  XSRF: 92,
  // Private variants cannot be configured to email users other than the owner
  VariantsEmailConstriction: 93,
  // You can only request a permissions upgrade once per 24-hour period.
  PermissionsUpgradeThrottled: 94,
  // This API does not allow the current authentication type.
  ProhibitedAuthType: 95,
  // Incorrect current password.
  PasswordChange: 96,
  // Changing host or scheme in redirect is forbidden.
  RedirectProhibited: 97,
  // We couldn&#39;t log you in with the provided credentials. Please ask your administrator for assistance.
  InvalidLoginGroup: 98,
  // Please re-supply your credentials.
  InRestrictedAccessMode: 99,
  // Unable to remove item. It is being processed.
  QueueItemInProgress: 100,
  // The provided password change token is invalid.
  InvalidPasswordToken: 101,
  // A schedule for this variant already exists.
  DuplicateSchedule: 102,
  // This system has not been configured to send email. Please contact your administrator.
  MissingServerAddress: 103,
  // The content checksum header and body MD5 sum are not equal.
  ContentChecksum: 104,
  // TensorFlow Model APIs are not permitted by your license.
  TensorFlowNotLicensed: 105,
  // Unable to update environment variables because they were changed while you were editing them.
  EnvironmentVersionMismatch: 106,
  // That username is not allowed because it is prohibited by policy.
  ProhibitedUsername: 107,
  // Environment changes contain a prohibited variable
  ProhibitedEnvironment: 108,
  // This type of content is not allowed because it is prohibited by policy.
  ProhibitedAppMode: 109,
  // You cannot change the categorization of content once deployed. Try deploying this as a new application instead of updating an existing one.
  ContentCategoryChanged: 110,
  // You cannot change if an app is parameterized once deployed. Try deploying this as a new application instead of updating an existing one.
  HasParametersChanged: 111,
  // The provided user role is not recognized.
  InvalidAuthRole: 112,
  // Invalid MaxProcesses setting. The MaxProcesses setting may not exceed the Scheduler.MaxProcessesLimit setting.
  MaxProcessesOverLimit: 113,
  // Invalid MinProcesses setting. The MinProcesses setting may not exceed the MaxProcesses setting.
  MinProcessesOverMaxProcesses: 114,
  // The provided status is not recognized.
  InvalidUserStatus: 115,
  // The requested rendering does not match the variant.
  VariantRenderingMismatch: 116,
  // Unknown access type.
  UnknownAccessType: 117,
  // This access type is not allowed because it is prohibited by policy.
  ProhibitedAccessType: 118,
  // The authentication provider does not support creating records from a retrieved ticket. POST new content instead.
  PullOnPushAuth: 119,
  // The authentication provider does not support creating records from POST content. PUT a retrieved ticket instead.
  PushOnPullAuth: 120,
  // The request JSON is invalid.
  InvalidJson: 121,
  // Application title must be between 3 and 1024 characters.
  InvalidApplicationTitle: 122,
  // Application description must be 4096 characters or less.
  InvalidApplicationDescription: 123,
  // No administrator has a configured email address.
  AdminMissingEmail: 124,
  // Content-Length cannot be 0.
  InvalidContentLength: 125,
  // Request Content-Length did not match the number of bytes received.
  BytesWrittenMismatch: 126,
  // The temp_ticket is invalid.
  InvalidTempTicket: 127,
  // The email address cannot be blank.
  UserBlankEmail: 128,
  // The user unique ID cannot be blank.
  UserBlankProviderKey: 129,
  // The group unique ID cannot be blank.
  GroupBlankProviderKey: 130,
  // A group using the same unique ID already exists.
  DuplicateGroupKey: 131,
  // The configured provider cannot access this endpoint.
  InvalidAuthProvider: 132,
  // The source variant belongs to a different app.
  SourceVariantInWrongApp: 133,
  // Unable to write bundle to disk.
  BundleWrite: 134,
  // Unable to extract the bundle.
  BundleExtract: 135,
  // Time values must be in RFC3339 format.
  InvalidTimeFormat: 136,
  // The start of the time interval cannot be in the past, or more than 5 years in the future.
  InvalidTimeIntervalStart: 137,
  // The end of the time interval cannot be earlier than the start time.
  InvalidTimeIntervalEnd: 138,
  // The length of the time interval cannot be more than 1 year.
  InvalidTimeIntervalLength: 139,
  // The time interval must have both start and end times.
  InvalidTimeInterval: 140,
  // Task lookup failures can indicate that a load balancer is not using sticky sessions or a client is not including the session cookie. Details: https://docs.posit.co/connect/admin/load-balancing/
  TaskDoesNotExist: 141,
  // The app is already managed by git.
  AppIsGitManaged: 142,
  // The app is not managed by git.
  AppIsNotGitManaged: 143,
  // Uploading a content bundle is not allowed for this application since it is managed by git.
  NoBundleUploadForGitManagedApp: 144,
  // Git support is disabled.
  GitIsDisabled: 145,
  // Git URLs must be absolute and cannot have username, query, or fragment.
  GitUnsupportedParameters: 146,
  // The specified app mode is not executable.
  AppModeIsNotExecutable: 147,
  // The specified app mode is not valid.
  InvalidAppMode: 148,
  // Environment changes contain a duplicated variable name.
  DuplicateEnvironmentVariable: 149,
  // The load factor must be between 0.0 and 1.0.
  InvalidLoadFactor: 150,
  // The timeout must be between 0 and 2592000 seconds.
  InvalidTimeout: 151,
  // The principal type must be &#39;user&#39; or &#39;group&#39;.
  InvalidPrincipalType: 152,
  // The requested group could not be found.
  InvalidGroup: 153,
  // The requested user is already in the content permission list.
  DuplicateUserPermission: 154,
  // The requested group is already in the content permission list.
  DuplicateGroupPermission: 155,
  // This user cannot be assigned as the owner because they don&#39;t have permission to publish content.
  InvalidOwner: 156,
  // The requested parent tag does not exist
  InvalidParentTag: 157,
  // The requested tag does not exist
  InvalidTag: 158,
  // The permission request submitted already exists.
  DuplicatePermissionRequest: 159,
  // The email notification for a permission request cannot be delivered due to missing or invalid email address.
  PermissionRequestInvalidEmail: 160,
  // The include option specified is not valid
  InvalidInclude: 161,
  // The specified hostname is not an active Connect host
  InvalidHost: 162,
  // The specified job cannot be terminated because it is not active
  InactiveJob: 163,
  // Git URLs must use HTTP or HTTPS.
  GitUnsupportedProtocol: 164,
  // Cannot create bootstrapping user due to existing users
  ProhibitedBootstrap: 165,
  // The provided JWT is invalid
  InvalidJWT: 166,
  // The controls file coundn&#39;t be read.
  ReadingControlsFile: 167,
  // The title must be between 1 and 64 characters.
  InvalidEnvironmentTitle: 168,
  // The description cannot be more than 4096 characters.
  InvalidEnvironmentDescription: 169,
  // The cluster_name must be &#39;Kubernetes&#39;.
  InvalidEnvironmentClusterName: 170,
  // A unique name for the environment must be provided.
  InvalidEnvironmentName: 171,
  // Unknown matching type.
  UnknownEnvironmentMatchingType: 172,
  // The installation path must be an absolute Unix filepath.
  InvalidInstallationPath: 173,
  // The installation version must use semantic version format.
  InvalidInstallationVersion: 174,
  // An environment with that name already exists.
  DuplicateEnvironmentName: 175,
  // The specified service account name is not a valid DNS subdomain.
  InvalidServiceAccountName: 176,
  // The supervisor path may not contain any of the following whitespace control characters: \t\n\b\f\n
  InvalidEnvironmentSupervisor: 177,
  // The specified service account name is not recognized by launcher in the Kubernetes namespace.
  UnrecognizedServiceAccountName: 178,
  // Unsupported runtime language identifier
  InvalidRuntimeLanguage: 179,
  // The request did not identify a cache location
  InvalidCacheLocation: 180,
  // Invalid MemoryRequest setting. The MemoryRequest setting cannot be less than 0 or greater than 9007198180999168.
  InvalidMemoryRequest: 181,
  // Invalid MemoryRequest setting. The MemoryRequest setting may not exceed the Scheduler.MaxMemoryRequest setting.
  MemoryRequestOverLimit: 182,
  // Invalid MemoryLimit setting. The MemoryLimit setting cannot be less than 0 or greater than 9007198180999168.
  InvalidMemoryLimit: 183,
  // Invalid MemoryLimit setting. The MemoryLimit setting may not exceed the Scheduler.MaxMemoryLimit setting.
  MemoryLimitOverLimit: 184,
  // Invalid MemoryRequest setting. The MemoryRequest setting may not exceed the MemoryLimit setting.
  MemoryRequestOverMemoryLimit: 185,
  // Invalid CPURequest setting. The CPURequest setting must be a positive number.
  InvalidCPURequest: 186,
  // Invalid CPURequest setting. The CPURequest setting may not exceed the Scheduler.MaxCPURequest setting.
  CPURequestOverLimit: 187,
  // Invalid CPULimit setting. The CPULimit setting must be a positive number.
  InvalidCPULimit: 188,
  // Invalid CPULimit setting. The CPULimit setting may not exceed the Scheduler.MaxCPULimit setting.
  CPULimitOverLimit: 189,
  // Invalid CPURequest setting. The CPURequest setting may not exceed the CPULimit setting.
  CPURequestOverCPULimit: 190,
  // Invalid MinProcesses setting. The MinProcesses setting must be a positive number.
  InvalidMinProcesses: 191,
  // Invalid MaxProcesses setting. The MaxProcesses setting must be a positive number.
  InvalidMaxProcesses: 192,
  // When provided, the default_image_name cannot be empty and may not contain any whitespace characters.
  InvalidDefaultImageName: 193,
  // Runtime cache management is disabled.
  CacheManagementIsDisabled: 194,
  // Invalid configuration. Service accounts cannot be set on content. Please contact an administrator to update Connect&#39;s server configuration to enable this feature.
  InvalidServiceAccountConfiguration: 195,
  // Invalid AMDGPULimit setting. The AMDGPULimit setting cannot be less than 0.
  InvalidAMDGPULimit: 196,
  // Invalid AMDGPULimit setting. The AMDGPULimit setting may not exceed the Scheduler.MaxAMDGPULimit setting.
  AMDGPULimitOverLimit: 197,
  // Invalid NvidiaGPULimit setting. The NvidiaGPULimit setting cannot be less than 0.
  InvalidNvidiaGPULimit: 198,
  // Invalid NvidiaGPULimit setting. The NvidiaGPULimit setting may not exceed the Scheduler.MaxNvidiaGPULimit setting.
  NvidiaGPULimitOverLimit: 199,
  // No valid recipients.
  EmailNoRecipients: 200,
  // Invalid default OAuth scope.
  InvalidDefaultOAuthScope: 201,
  // Invalid default OAuth scopes. The number of default scopes cannot be greater than 1024.
  DefaultOAuthScopesOverLimit: 202,
  // OAuth client id must be between 1 and 1024 characters.
  InvalidOAuthClientId: 203,
  // OAuth tenant id must be between 1 and 1024 characters.
  InvalidOAuthTenantId: 204,
  // OAuth client secret must be between 1 and 1024 characters.
  InvalidOAuthClientSecret: 205,
  // OAuth description must be between 1 and 1024 characters.
  InvalidOAuthDescription: 206,
  // Invalid OAuth integration GUID. Submitted GUIDs must represent a valid OAuth integration.
  InvalidOAuthIntegrationGUID: 207,
  // Invalid OAuth integrations. The number of integrations cannot be greater than 1.
  InvalidOAuthIntegrations: 208,
  // Invalid OAuth association assignment. OAuth associations cannot be assigned to static content types.
  InvalidOAuthAssociationAssignment: 209,
  // Form-post not found in request.
  FormPostNotFound: 210,
  // Unable to parse form-post from request.
  FormPostParseFailure: 211,
  // No OAuth integrations have been associated with this content item.
  OAuthCredentialNoAssociations: 212,
  // Invalid grant_type. Expected &#39;urn:ietf:params:oauth:grant-type:token-exchange&#39;
  OAuthCredentialGrantType: 213,
  // Invalid subject_token_type. Expected &#39;urn:posit:connect:user-session-token&#39; or &#39;urn:posit:connect:content-session-token&#39;
  OAuthCredentialSubjectTokenType: 214,
  // Invalid subject_token.
  OAuthCredentialSubjectToken: 215,
  // Iss claim must match the server address.
  OAuthCredentialIssClaim: 216,
  // Sub claim must be a valid GUID.
  OAuthCredentialSubClaim: 217,
  // Invalid OAuth session. No refresh token found.
  InvalidOAuthSession: 218,
  // Failed to refresh OAuth Access Token.
  OAuthAccessTokenRefreshFailure: 219,
  // No OAuth session found. Log in to the OAuth Integration before requesting credentials.
  OAuthSessionNotFound: 220,
  // OAuth integrations are not permitted by your license.
  OAuthIntegrationsNotLicensed: 221,
  // Cannot deploy or update bundles for locked content.
  LockedContentBundle: 222,
  // Cannot start new processes for locked content.
  LockedContentProcess: 223,
  // Locked message must be less than 4096 alphanumeric characters.
  InvalidLockedMessage: 224,
  // Invalid OAuth integration name. Name must be a single line string.
  InvalidOAuthIntegrationName: 225,
  // Invalid OAuth integration description. Description must be a single line string and cannot be longer than 4096 characters.
  InvalidOAuthIntegrationDescription: 226,
  // OAuth template not found.
  OAuthTemplateNotFound: 227,
  // OAuth template configuration invalid.
  OAuthTemplateConfig: 228,
  // OAuth template cannot be changed.
  OAuthTemplateProhibitedChange: 229,
  // Invalid OAuth integration login request.
  InvalidOAuthIntegrationLogin: 230,
  // No content.
  NoContent: 231,
  // The URL provided to download a bundle for a given content item is not valid.
  InvalidBundleURL: 232,
  // Unable to fetch the bundle from the URL provided.
  UnableToGetBundleFromURL: 233,
  // The provided bundle for the extension type content cannot be read.
  InvalidExtensionTOML: 234,
  // You don&#39;t have permission to give the key this role.
  InvalidKeyRole: 235,
  // Invalid OAuth refresh token. Log in to the integration again to acquire a new refresh token.
  OAuthInvalidRefreshToken: 236,
  // Invalid &#39;urn:posit:connect:content-session-token&#39;
  OAuthInvalidContentSessionToken: 237,
  // Invalid OAuth association auth type. Rendered or public content cannot be associated with a &#39;Viewer&#39; OAuth integration.
  OAuthAssociationAuthType: 238,
  // Session token auth flow type does not match associated integration.
  OAuthAuthTypeMismatch: 239,
  // Invalid MaxConnsPerProcess setting. The MaxConnsPerProcess setting must be greater than 0.
  InvalidMaxConnsPerProcess: 240,
  // Invalid OAuth service account integration test auth type. Cannot test &#39;Viewer&#39; OAuth integrations, only &#39;Service Account&#39; OAuth integrations.
  OAuthServiceAccountTestInvalidAuthType: 241,
  // Unable to retrieve OAuth token for integration.
  OAuthUnableToRetrieveToken: 242,
};

export default ApiErrors;
